<template>
    <section class="user-detail">
        <div v-if="userInfo" class="head-box">
            <img class="head-img" :src="userInfo.headimgurl ? userInfo.headimgurl : require('@a/img/product2.png')" alt="">
            <span class="head-name text-ellipsis">{{ userInfo.username }}</span>
            <span class="head-accout">{{ userInfo.phone }}</span>
        </div>
        <div v-if="userInfo" class="head-tips-box">
            <h3 class="head-tips-tit">
                <i class="icon icon-more" />个人中心
            </h3>
            <div v-if="userInfo.status===2" class="box-round head-tips">TIPS：用户名违规，请<a href="javascript:;" @click.prevent="$router.push('/my/accout')">立即修改</a></div>
            <div class="head-num head-num-reverse">
                <router-link class="head-num-item" to="/my/k-point">
                    <i class="head-num-num">{{ isuseIntergal }}</i>K点
                </router-link>
                <router-link class="head-num-item" to="/my/order/3">
                    <i class="head-num-num">{{ userInfo.daifukuan || 0 }}</i>待付款
                </router-link>
                <router-link class="head-num-item" to="/my/order/4">
                    <i class="head-num-num">{{ userInfo.daifahuo || 0 }}</i>待发货
                </router-link>
                <router-link class="head-num-item" to="/my/message">
                    <i class="head-num-num">{{ +userInfo.news > 99 ? `${userInfo.news}+` : userInfo.news }}</i>消息
                </router-link>
            </div>
        </div>
    </section>
</template>
<script>
import { getUserIntegral } from '@api/order'
export default {
    name: 'MyMainHead',
    props: ['userInfo'],
    data() {
        return {
            isuseIntergal: 0
        }
    },
    methods: {
        async getUserIntegral() {
            const res = await getUserIntegral()
            if (res && res.detail) {
                // 缓存登录信息
                // this.userInfoObj = Object.assign({}, res.detail)
                // console.log('userInfoObj1', this.userInfoObj)
                this.isuseIntergal = res.detail.integral
            }
        },
    },
    created() {
        this.getUserIntegral()
    }
}
</script>
<style lang="scss" scoped>
.user-detail {
    margin-bottom: 30px;
}
.head-box,
.head-tips-box {
    @include inlineBlock();
}
.head-box {
    width: 285px;
    padding: 20px;
    text-align: center;
    color: $white;
    background-color: $redest;
    > * {
        display: block;
        margin: 0 auto 12px;
    }
}
.head-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.head-name {
    font-size: 14px;
}
.head-accout {
    @include inlineBlock();
    padding: 5px 10px;
    margin-bottom: 0;
    border-radius: 20px;
    background-color: $reddark;
}
.head-tips-box {
    width: 610px;
    height: 160px;
    padding: 20px;
    background-color: $white;
}
.head-num {
    padding-top: 20px;
    border-top: 1px dashed $lighter;
    text-align: center;
    color: $light;
}
.head-num-item {
    @include inlineBlock();
    width: 100px;
    margin: 0 10px;
    font-size: 14px;
}
.head-num-num {
    display: block;
    margin-top: 2px;
    font-weight: 700;
    font-size: 28px;
    color: $red;
}
.head-tips-tit,
.box-round.head-tips {
    @include inlineBlock();
    margin-bottom: 20px;
}
.head-tips-tit {
    width: 350px;
    font-weight: bold;
    font-size: 18px;
    color: $reder;
    .icon-more {
        margin-right: 10px;
    }
}
</style>
