<template>
    <div class="my-home">
        <my-main-head :user-info="userInfo" />
        <h3 class="prod-title">关注提醒<small>关注的商品可购买时会在这里提醒</small></h3>
        <info-list v-if="infoList[0]" class="col-three" :info-list="infoList" />
        <section v-else class="prod-list">
            <div class="nodata">
                <h3 class="nodata-tit">尚未设置关注</h3>
                <a class="btn btn-primary btn-round nodata-go" href="/">前去设置</a>
            </div>
        </section>
    </div>
</template>
<script>
import myMainHead from './myMainHead.vue'
import infoList from '@c/infoList.vue'
import { userCollection } from '@api/my'
import { getUserAccount } from '@api'
// import './mock'
export default {
    name: 'MyHome',
    components: {
        myMainHead,
        infoList
    },
    props: {
        userInfo: {
            type: Object
        }
    },
    data() {
        return {
            infoList: [],
        }
    },
    created() {
        const self = this
        // 获取关注列表
        this.userCollection()
    },
    methods: {
        async userCollection() {
            const res = await userCollection()
            // console.log('userCollection res', res)
            // const list = res.list
            if (res && res.code === 1001) {
                this.infoList = res.list ? res.list.list : []
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.prod-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
    color: $black3;
    > small {
        padding: 0 10px;
        font-size: 12px;
        color: $light;
    }
}
.nodata {
    padding: 100px 50px;
    text-align: center;
    > * {
        margin: 10px auto;
    }
}
.nodata-tit {
    font-size: 18px;
}
.nodata-go {
    width: 196px;
}
</style>
